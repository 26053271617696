import { getSingleReport } from "../../services/API";

export const loadCurrentReport = (areaId, type, id) => async (dispatch) => {
  dispatch({
    type: "SET_AREA_DATA",
    areaId: areaId,
    data: {
      currentReportLoading: id,
    },
  });

  try {
    let data = await getSingleReport(areaId, type, id);
    dispatch({
      type: "SET_AREA_DATA",
      areaId: areaId,
      data: {
        currentReport: data,
        currentReportLoading: false,
      },
    });
  } catch (e) {
    dispatch({
      type: "SET_AREA_DATA",
      areaId: areaId,
      data: {
        currentReportLoading: false,
      },
    });
  }
};
