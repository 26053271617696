import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export default class Tooltip extends React.Component {
  static propTypes = {
    styles: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    if (props.styles) this.mergeStyles(props.styles);
  }

  styles = {
    wrapper: {
      position: "relative",
      display: "inline-block",
      zIndex: "98",
      color: "#555",
      cursor: "help",
    },
    tooltip: {
      position: "absolute",
      zIndex: "99",
      maxWidth: "500px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      background: "rgba(35,35,35,0.95)",
      top: "100%",
      left: "50%",
      padding: "2px",
      WebkitTransform: "translateX(-50%)",
      msTransform: "translateX(-50%)",
      OTransform: "translateX(-50%)",
      transform: "translateX(-50%)",
      borderRadius: 4,
    },
    content: {
      color: "#fff",
      fontWeight: 600,
      display: "inline-block",
      fontSize: "0.8em",
      padding: "0.3em 0.7em",
    },
  };

  mergeStyles = (userStyles) => {
    Object.keys(this.styles).forEach((name) => {
      Object.assign(this.styles[name], userStyles[name]);
    });
  };

  show = () => this.setVisibility(true);

  hide = () => this.setVisibility(false);

  setVisibility = (visible) => {
    this.setState(
      Object.assign({}, this.state, {
        visible,
      })
    );
  };

  handleTouch = () => {
    this.show();
    this.assignOutsideTouchHandler();
  };

  assignOutsideTouchHandler = () => {
    const handler = (e) => {
      let currentNode = e.target;
      const componentNode = ReactDOM.findDOMNode(this.refs.instance);
      while (currentNode.parentNode) {
        if (currentNode === componentNode) return;
        currentNode = currentNode.parentNode;
      }
      if (currentNode !== document) return;
      this.hide();
      document.removeEventListener("touchstart", handler);
    };
    document.addEventListener("touchstart", handler);
  };

  render() {
    const { props, state, styles, show, hide, handleTouch } = this;
    return (
      <div
        className="tooltip-wrapper"
        onMouseEnter={show}
        onMouseLeave={hide}
        onTouchStart={handleTouch}
        ref="wrapper"
        style={styles.wrapper}
      >
        {props.children}
        {state.visible && (
          <div ref="tooltip" style={styles.tooltip}>
            <div ref="content" style={styles.content}>
              {props.content.split("\n").map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
