import React from "react";
import Props from "prop-types";

export default class Icon extends React.Component {
  static propTypes = {
    children: Props.string.isRequired,
    color: Props.string,
    rotating: Props.bool,
    className: Props.string,
    style: Props.object,
    onClick: Props.func,
  };

  static defaultProps = {
    rotating: false,
    onClick: () => {},
    style: {},
  };

  render() {
    const style = Object.assign(
      {},
      this.props.color ? { color: this.props.color } : {},
      this.props.style
    );

    const classes =
      "material-icons" +
      (this.props.className ? " " + this.props.className : "") +
      (this.props.rotating ? " rotating" : "");

    return (
      <i className={classes} style={style} onClick={this.props.onClick}>
        {this.props.children}
      </i>
    );
  }
}
