import React from "react";
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
} from "react-google-maps";

import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";

import mapStyle from "../Map/mapStyle.js";

class EditableMap extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  mapRef = null;
  searchRef = null;
  polyRef = null;

  state = {
    coordinates: [],
  };

  getArrayBounds(polyArray) {
    const bounds = new google.maps.LatLngBounds();

    for (let polys = 0; polys < polyArray.length; polys++) {
      const coord = polyArray[polys];
      bounds.extend({ lat: coord[0], lng: coord[1] });
    }

    return bounds;
  }

  setArea(coordinates) {
    this.setState({ coordinates });

    console.log('Fitting bounds');

    if (this.mapRef) {
      this.mapRef.fitBounds(this.getArrayBounds(coordinates), {
        left: 20,
        top: 40,
        right: 40,
        bottom: 20,
      });
    }
  }

  async setAreaWithPlaces(center) {
    const x = center.lat - 0.00075;
    const y = center.lng;
    const r = 0.003;

    const triangleCoords = [
      [x - r, y - r * 1.5],
      [x + r, y - r * 1.5],
      [x + r, y + r * 1.5],
      [x - r, y + r * 1.5],
    ];

    await this.setArea(triangleCoords);
    this.triggerUpdate();
  }

  triggerUpdate() {
    if (!this.polyRef) {
      return;
    }

    const vertices = this.polyRef.getPath();

    const contentString = [];

    // Iterate over the vertices.
    for (let i = 0; i < vertices.getLength(); i++) {
      const xy = vertices.getAt(i);
      contentString.push([xy.lat(), xy.lng()]);
    }

    this.props.onChange(contentString);
  }

  setMapRef(mapRef) {
    this.mapRef = mapRef;
    this.props.onRef(this);
  }

  render() {
    return (
      <>
        {this.props.canSearch ? (
        <div style={{
          position: 'absolute',
          left: 10,
          top: 10,
          width: 200
        }}>
        <GooglePlacesAutocomplete
          selectProps={{
            placeholder: "Search an address...",
            components: {
              ClearIndicator: () => null,
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            },
            onChange: async ({ value }) => {
              if (!value) {
                return;
              }
              console.log(value);
              const geo = await geocodeByPlaceId(value.place_id);
              if (!geo.length) {
                alert("Sorry, could not find coordinates for this location!");
                return;
              }
              const coords = await getLatLng(geo[0]);
              this.setAreaWithPlaces(coords);
            },
          }}
        />
        </div>
        ) : null}
        <GoogleMap
          key="editmap"
          options={{
            styles: mapStyle,
            disableDefaultUI: true,
            zoomControl: true,
          }}
          ref={(mapRef) => this.setMapRef(mapRef)}
          defaultZoom={15}
          defaultCenter={{ lat: 50.8542971, lng: 5.7289186 }}
        >
          {this.state.coordinates.length ? (
            <Polygon
              key="area"
              options={{
                strokeColor: "#2d89ea",
                strokeWeight: 3,
                fillColor: "#2d89ea",
                fillOpacity: 0.4,
                editable: true,
              }}
              ref={(ref) => {
                if (!ref || !ref.getPath) {
                  return;
                }
                this.polyRef = ref;
                const poly = ref.getPath();

                google.maps.event.addListener(poly, "insert_at", () => {
                  this.triggerUpdate();
                });
                google.maps.event.addListener(poly, "set_at", () => {
                  this.triggerUpdate();
                });
                google.maps.event.addListener(poly, "remove_at", () => {
                  this.triggerUpdate();
                });
              }}
              path={this.state.coordinates.map(([lat, lng]) => ({ lat, lng }))}
            />
          ) : null}
        </GoogleMap>
      </>
    );
  }
}

export default compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBAFaFbACgQkoC9LktL2VLxRHmb610OZWs&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%`, position: 'relative' }} />,
    containerElement: <div style={{ height: `100%`, position: 'relative' }} />,
    mapElement: <div style={{ height: `100%`, position: 'relative' }} />,
  }),
  withScriptjs,
  withGoogleMap
)(EditableMap);
