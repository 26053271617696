import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import slug from "slug";
import { Link, Redirect } from "react-router-dom";

import Icon from "../../components/Icon";
import EditableMap from "../../components/EditableMap";
import {
  createArea,
  createUser,
  deleteArea,
  deleteUser,
  getOrgUsers,
  getUserAreas,
  getUsers,
  updateArea, updateUser
} from "../../services/API";
import Tooltip from "../../components/Tooltip";

export default class AreaEdit extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        userId: PropTypes.string,
      }),
    }),
  };

  state = {
    saving: false,
    isNew: true,
    mapRef: null,
    redirect: false,
    changedSla: false,
    selectedArea: null,
    data: {
      name: "",
    },
    users: [],
    areas: [],
  };

  async componentDidMount() {
    const { userId } = this.props.match.params;
    this.setState({
      isNew: !!!userId,
    });

    if (!userId) {
      return;
    }

    this.setState({
      data: {
        name: userId,
      },
    });

    const areas = await getUserAreas(userId);
    this.setState({
      areas,
    });

    const users = await getOrgUsers(userId);
    this.setState({ users });
  }

  async save() {
    const { saving, isNew, data, areas } = this.state;
    let { userId } = this.props.match.params;

    const org = isNew ? slug(data.name) : userId;
    if (isNew) {
      if (!data.name) {
        alert("Geef een naam op voor deze klant.");
        return;
      }
      const allUsers = await getUsers();
      if (allUsers.some((user) => user.org === org)) {
        alert(
          "Er is al een klant met de naam '" + org + "'. Kies een andere naam."
        );
        return;
      }
    }

    if (isNew && !areas.length) {
      alert("Voeg minimaal één gebied toe om deze klant op te slaan.");
      return;
    }

    if (saving) {
      return;
    }

    let error = false;
    areas.forEach((area) => {
      if (error || area.isDeleted || !(area.isNew || area.isUpdated)) {
        return;
      }
      if (area.timePerDay < 0 || area.timePerDay >= 86400) {
        alert(
          "Geef een aantal uren surveillance per dag op voor " +
            area.title +
            " (min 1, max 23)"
        );
        error = true;
        return;
      }
      if (area.fromTime < 0 || area.fromTime >= 86400) {
        alert(
          "Geef een begintijd op voor gebied " + area.title + " (min 0, max 23)"
        );
        error = true;
        return;
      }
      if (area.untilTime < 0 || area.untilTime >= 86400) {
        alert(
          "Geef een eindtijd op voor gebied " + area.title + " (min 0, max 23)"
        );
        error = true;
      }
    });

    if (error) {
      return;
    }

    this.setState({ saving: true });

    try {
      await Promise.all(
        areas.map(async (area) => {
          if (area.isNew) {
            return createArea(
              org,
              area.title,
              area.timePerDay,
              area.fromTime,
              area.timePerWeekday,
              area.untilTime,
              area.coordinates,
              area.maxSpeed,
              area.maxStopTime
            );
          }
          if (area.isUpdated) {
            return updateArea(
              org,
              area.id,
              area.title,
              area.timePerDay,
              area.fromTime,
              area.timePerWeekday,
              area.untilTime,
              area.coordinates,
              area.maxSpeed,
              area.maxStopTime
            );
          }
          if (area.isDeleted) {
            return deleteArea(area.id);
          }
        })
      );
    } catch (e) {
      console.log(e);
      alert(
        (e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.message) ||
          e.message
      );
      this.setState({ saving: false });
      return;
    }

    if (isNew) {
      window.location.href = "/admin/edit/" + org;
      return;
    }

    this.setState({ saving: false, redirect: true });
  }

  selectArea(area) {
    this.setState({ selectedArea: area });
    console.log(area.coordinates);
    if (this.mapRef) {
      console.log("mapref");
      this.mapRef.setArea(area.coordinates);
    }
  }

  renameArea(area) {
    const newTitle = prompt(
      "Wat is de nieuwe titel voor het gebied?",
      area.title
    );
    if (!newTitle) {
      return;
    }

    area.title = newTitle;
    area.isUpdated = true;

    this.selectArea(area);
  }

  async removeArea(area) {
    const { areas } = this.state;

    if (!confirm("Weet je zeker dat je dit gebied wil verwijderen?")) {
      return;
    }

    area.isDeleted = true;

    this.setState({
      areas: [...areas.filter((ar) => ar.id !== area.id), area],
    });
  }

  updateArea(coordinates) {
    const { selectedArea } = this.state;
    if (!selectedArea) {
      return;
    }

    selectedArea.coordinates = coordinates;
    selectedArea.isUpdated = true;

    this.selectArea(selectedArea);
  }

  updateAreaProp(prop, val) {
    const { selectedArea } = this.state;
    if (!selectedArea) {
      return;
    }

    if (prop !== "maxSpeed" && prop !== "maxStopTime") {
      selectedArea[prop] = parseFloat(val) * 3600;
    } else {
      selectedArea[prop] = parseFloat(val);
    }
    selectedArea.isUpdated = true;

    this.selectArea(selectedArea);
  }

  updateWeekdayTime(day, val) {
    const { selectedArea } = this.state;
    if (!selectedArea) {
      return;
    }

    selectedArea.timePerWeekday[day] = Math.max(0, parseFloat(val)) * 3600;
    selectedArea.isUpdated = true;

    this.selectArea(selectedArea);
  }

  getDefaultCoords() {
    const x = 50.8542971 - 0.00075;
    const y = 5.7289186;
    const r = 0.003;

    return [
      [x - r, y - r * 1.5],
      [x + r, y - r * 1.5],
      [x + r, y + r * 1.5],
      [x - r, y + r * 1.5],
    ];
  }

  addArea() {
    const { areas } = this.state;

    let title = prompt("Hoe heet het nieuwe gebied?");
    if (!title || !title.length) {
      return;
    }
    title = title.trim();
    if (!title.length) {
      return;
    }

    const newArea = {
      isNew: true,
      id: uuid(),
      title,
      timePerDay: 3600,
      timePerWeekday: {
        Mon: 3600,
        Tue: 3600,
        Wed: 3600,
        Thu: 3600,
        Fri: 3600,
        Sat: 3600,
        Sun: 3600,
      },
      maxStopTime: 30,
      maxSpeed: 55,
      fromTime: 18 * 3600,
      untilTime: 6 * 3600,
      coordinates: this.getDefaultCoords(),
    };

    this.setState({ areas: [...areas, newArea] });
    this.selectArea(newArea);
  }

  async removeSubuser(userId) {
    if (
      !confirm(
        "Weet je zeker dat je deze gebruiker wil verwijderen?"
      )
    ) {
      return;
    }

    await deleteUser(userId);

    return this.componentDidMount();
  }

  async addUser() {
    let { userId } = this.props.match.params;

    const email = prompt(
      "Wat is het emailadres van de gebruiker die je wil uitnodigen?"
    );
    if (!email || !email.length || email.indexOf("@") < 1) {
      return;
    }

    try {
      await createUser(this.state.data.name, email, userId);
    } catch (e) {
      alert(e.response?.data?.error?.message || e.message);
    }

    const users = await getOrgUsers(userId);
    this.setState({ users });
  }

  render() {
    const { isNew, users, data, areas, selectedArea, redirect } = this.state;

    if (redirect) {
      return <Redirect to="/admin" />;
    }

    return (
      <div className="admin-content">
        <div className="admin-nav">
          <Link to="/admin" className="admin-back">
            Annuleren
          </Link>
          {!this.state.saving ? (
            <a className="button" onClick={() => this.save()}>
              Opslaan
            </a>
          ) : null}
        </div>
        <div className="admin-inner">
          <div className="form-row">
            <div className="form-input">
              <label>Bedrijfsnaam</label>
              <input
                disabled={!isNew ? "disabled" : undefined}
                type="text"
                value={data.name}
                onChange={(e) =>
                  this.setState({ data: { ...data, name: e.target.value } })
                }
                placeholder="Acme, Inc."
              />
            </div>
          </div>
          <label>Gebieden</label>
          <div className="admin-area-editor">
            <div className="areas-list">
              <div>
                {areas.map(
                  (area) =>
                    !area.isDeleted && (
                      <div
                        key={area.id}
                        className={`area ${
                          selectedArea && area.id === selectedArea.id
                            ? "active"
                            : ""
                        }`}
                      >
                        <span onClick={() => this.selectArea(area)}>
                          {area.title}
                        </span>
                        <div>
                          <a onClick={() => this.selectArea(area)}>
                            <Icon>edit</Icon>
                          </a>
                          <a onClick={() => this.renameArea(area)}>
                            <Icon>font_download</Icon>
                          </a>
                          <a onClick={() => this.removeArea(area)}>
                            <Icon>delete</Icon>
                          </a>
                        </div>
                      </div>
                    )
                )}
              </div>
              <a
                className="button button-text button-secondary"
                onClick={() => this.addArea()}
              >
                <Icon>add_circle</Icon> Gebied toevoegen
              </a>
            </div>
            <div key="areas-map" className="areas-map">
              <div style={{ opacity: selectedArea ? 1 : 0, height: "100%" }}>
                {selectedArea ? (
                  <div className="form-rows">
                    <div className="form-row">
                      <div className="form-input">
                        <label>Van uur</label>
                        <input
                          key="areaFromTime"
                          type="number"
                          onChange={(e) =>
                            this.updateAreaProp("fromTime", e.target.value)
                          }
                          value={selectedArea.fromTime / 3600}
                          placeholder="22"
                        />
                      </div>
                      <div className="form-input">
                        <label>Tot uur</label>
                        <input
                          key="areaUntilTime"
                          type="number"
                          onChange={(e) =>
                            this.updateAreaProp("untilTime", e.target.value)
                          }
                          value={selectedArea.untilTime / 3600}
                          placeholder="7"
                        />
                      </div>
                      <div className="form-input">
                        <label>Max snelheid (km/h)</label>
                        <input
                          type="number"
                          value={selectedArea.maxSpeed}
                          onChange={(e) =>
                            this.updateAreaProp(
                              "maxSpeed",
                              parseFloat(e.target.value)
                            )
                          }
                          placeholder="55"
                        />
                      </div>
                      <div className="form-input">
                        <label>Max stilstaan (min)</label>
                        <input
                          type="number"
                          value={selectedArea.maxStopTime}
                          onChange={(e) =>
                            this.updateAreaProp(
                              "maxStopTime",
                              parseFloat(e.target.value)
                            )
                          }
                          placeholder="30"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <label>Maandag (h/dag)</label>
                        <input
                          key="areaTimePerDayMon"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.updateWeekdayTime("Mon", e.target.value)
                          }
                          value={selectedArea.timePerWeekday.Mon / 3600}
                          placeholder="2.5"
                        />
                      </div>
                      <div className="form-input">
                        <label>Dinsdag (h/dag)</label>
                        <input
                          key="areaTimePerDayTue"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.updateWeekdayTime("Tue", e.target.value)
                          }
                          value={selectedArea.timePerWeekday.Tue / 3600}
                          placeholder="2.5"
                        />
                      </div>
                      <div className="form-input">
                        <label>Woensdag (h/dag)</label>
                        <input
                          key="areaTimePerDayWed"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.updateWeekdayTime("Wed", e.target.value)
                          }
                          value={selectedArea.timePerWeekday.Wed / 3600}
                          placeholder="2.5"
                        />
                      </div>
                      <div className="form-input">
                        <label>Donderdag (h/dag)</label>
                        <input
                          key="areaTimePerDayThu"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.updateWeekdayTime("Thu", e.target.value)
                          }
                          value={selectedArea.timePerWeekday.Thu / 3600}
                          placeholder="2.5"
                        />
                      </div>
                      <div className="form-input">
                        <label>Vrijdag (h/dag)</label>
                        <input
                          key="areaTimePerDayFri"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.updateWeekdayTime("Fri", e.target.value)
                          }
                          value={selectedArea.timePerWeekday.Fri / 3600}
                          placeholder="2.5"
                        />
                      </div>
                      <div className="form-input">
                        <label>Zaterdag (h/dag)</label>
                        <input
                          key="areaTimePerDaySat"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.updateWeekdayTime("Sat", e.target.value)
                          }
                          value={selectedArea.timePerWeekday.Sat / 3600}
                          placeholder="2.5"
                        />
                      </div>
                      <div className="form-input">
                        <label>Zondag (h/dag)</label>
                        <input
                          key="areaTimePerDaySun"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.updateWeekdayTime("Sun", e.target.value)
                          }
                          value={selectedArea.timePerWeekday.Sun / 3600}
                          placeholder="2.5"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <EditableMap
                  key="editor"
                  canSearch={!!selectedArea}
                  onChange={(coordinates) => this.updateArea(coordinates)}
                  onRef={(mapRef) => {
                    this.mapRef = mapRef;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="subusers">
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Gebruikers</th>
                </tr>
              </thead>
              {!isNew ? (
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td>{user.email + (user.admin ? " (admin)" : "")}</td>
                      <td style={{ textAlign: "right" }}>
                        <Tooltip
                          content={!user.admin ? "Maak admin": "Verwijder admin"}
                        >
                          <a onClick={async () => {
                            await updateUser(user.id, {admin: !user.admin})
                            user.admin = !user.admin;
                            this.componentDidMount();
                          }}>
                            <Icon color={user.admin ? '#f36e0d': null}>manage_accounts</Icon>
                          </a>
                        </Tooltip>
                        <a onClick={() => this.removeSubuser(user.id)}>
                          <Icon>delete</Icon>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      Sla deze klant op om nieuwe gebruikers toe te voegen.
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
            {!isNew ? (
              <a
                className="button button-text button-secondary"
                onClick={() => this.addUser()}
              >
                <Icon>add_circle</Icon> Gebruiker toevoegen
              </a>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
