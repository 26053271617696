import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Hub } from "aws-amplify";

import Navigation from "./Navigation";
import MobileCompat from "./MobileCompat";

import DayReport from "../containers/DayReport";
import MonthReport from "../containers/MonthReport";

import { loadData } from "../store/actions/loadData";
import Admin from "../containers/Admin";

class Main extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  async componentDidMount() {
    this.props.loadData();

    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
        case "tokenRefresh":
          this.props.loadData();
      }
    });
  }

  render() {
    if (this.props.loading) {
      return null;
    }

    return (
      <>
        <Navigation />
        <div className="body">
          <MobileCompat />
          {this.props.currentArea ? (
            <Switch>
              <Route path="/areas/:area/day/:id?" component={DayReport} />
              <Route path="/month/:id?" component={MonthReport} />
              <Route path="/admin" component={Admin} />
              <Route
                path="/"
                render={() =>
                  !this.props.currentArea ? null : (
                    <Redirect to={`/areas/${this.props.currentArea.id}/day`} />
                  )
                }
              />
            </Switch>
          ) : (
            <Switch>
              <Route path="/admin" component={Admin} />
            </Switch>
          )}
        </div>
      </>
    );
  }
}

export default connect(
  ({ app }) => ({
    loading: app.loading,
    currentArea: app.currentArea,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        loadData,
      },
      dispatch
    )
)(Main);
