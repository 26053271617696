import { getReports } from "../../services/API";

export const loadReports = (areaId, type) => async (dispatch) => {
  dispatch({
    type: "SET_AREA_DATA",
    areaId: areaId,
    data: {
      [type === "daily"
        ? "reportsDailyLoading"
        : "reportsMonthlyLoading"]: true,
    },
  });

  try {
    let data = await getReports(areaId, type);
    dispatch({
      type: "SET_AREA_DATA",
      areaId: areaId,
      data: {
        [type === "daily" ? "reportsDaily" : "reportsMonthly"]: data,
        [type === "daily"
          ? "reportsDailyLoading"
          : "reportsMonthlyLoading"]: false,
      },
    });
  } catch (e) {
    dispatch({
      type: "SET_AREA_DATA",
      areaId: areaId,
      data: {
        [type === "daily"
          ? "reportsDailyLoading"
          : "reportsMonthlyLoading"]: false,
      },
    });
  }
};
