import React from "react";
import PropTypes from "prop-types";
import Map from "../components/Map";
import Icon from "../components/Icon";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InfoWindow, Marker, Polygon, Polyline } from "react-google-maps";
import { NavLink, Redirect } from "react-router-dom";
import moment from "moment";

import { secondsToHumanTime } from "../services/timeFormatter";

import { loadReports } from "../store/actions/loadReports";
import { setCurrentArea } from "../store/actions/setCurrentArea";
import { loadCurrentReport } from "../store/actions/loadCurrentReport";

import Car from "../assets/car.png";

class DayReport extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  state = {
    vehicles: [],
    currentVehicle: 0,
    activeStop: null,
  };

  constructor(props) {
    super(props);

    this.mapReportId = null;
    this.mapRef = null;

    this.loadData(props);
  }

  componentDidUpdate() {
    this.loadData(this.props);

    this.updateMap();
  }

  componentDidMount() {
    this.updateMap();
  }

  async loadData() {
    let {
      area,
      match,
      loading,
      areas,
      setCurrentArea,
      loadReports,
      loadCurrentReport,
    } = this.props;

    if ((!area || area.id !== match.params.area) && !loading && areas) {
      const matchingAreas = areas.filter(
        (area) => area.id === match.params.area
      );
      if (matchingAreas.length) {
        area = matchingAreas[0];
        await setCurrentArea(area);
      }
    }

    if (area && !area.reportsDaily && !area.reportsDailyLoading) {
      await loadReports(match.params.area, "daily");
    }

    if (
      area &&
      area.reportsDaily &&
      (!area.currentReport || area.currentReport.id !== match.params.id) &&
      area.currentReportLoading !== match.params.id
    ) {
      await loadCurrentReport(match.params.area, "daily", match.params.id);
    }

    this.updateMap();
  }

  renderPoly(travel, key, currentLp) {
    const lines = [];
    let curLine = [];

    travel.geometry.coordinates.forEach(([lat, lng]) => {
      const last = curLine.length ? curLine[curLine.length - 1] : null;

      if (
        last &&
        Math.abs(last[0] - lat) > 0.001 &&
        Math.abs(last[1] - lng) > 0.001 &&
        curLine.length > 1
      ) {
        lines.push(curLine);
        curLine = [];
      }
      curLine.push([lat, lng]);
    });

    if (curLine.length > 1) {
      lines.push(curLine);
    }

    return lines.map((ln, id) => (
      <Polyline
        key={key + id + currentLp}
        options={{
          strokeColor:
            travel.vehicle === currentLp ? "#2d89ea" : "rgba(155,155,155,0.6)",
          strokeWeight: 6,
        }}
        path={ln.map(([lat, lng]) => ({ lat, lng }))}
      />
    ));
  }

  renderMarkers(report) {
    const currentLp =
      (this.state.vehicles &&
        this.state.vehicles[this.state.currentVehicle] &&
        this.state.vehicles[this.state.currentVehicle].licensePlate) ||
      "";

    let pins = report.area.travels
      .map((travel) => {
        const key = travel.from + "-" + travel.until;
        return [
          travel.vehicle === currentLp ? 1 : 0,
          travel.type === "parked" ? (
            travel.duration >= 70 ? (
              <Marker
                key={key}
                onClick={() => this.setState({ activeStop: key })}
                icon={
                  travel.vehicle === currentLp
                    ? "/images/stop.svg"
                    : "/images/stop-inactive.svg"
                }
                position={{
                  lat: travel.geometry.coordinates[0],
                  lng: travel.geometry.coordinates[1],
                }}
              >
                {this.state.activeStop === key ? (
                  <InfoWindow
                    onCloseClick={() => this.setState({ activeStop: null })}
                  >
                    <div>
                      <div className="info-window-times">
                        {`${moment(travel.from).format("HH:mm")} - ${moment(
                          travel.until
                        ).format("HH:mm")}`}
                      </div>
                      <div className="info-window-details">
                        Voertuig: {travel.vehicle}
                        <br />
                        <a
                          href={`http://www.google.com/maps?layer=c&cbll=${travel.geometry.coordinates.join(
                            ","
                          )}`}
                          target="_blank"
                        >
                          STREETVIEW
                        </a>
                      </div>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            ) : null
          ) : (
            this.renderPoly(travel, key, currentLp)
          ),
        ];
      })
      .sort((a, b) => a[0] - b[0])
      .map((x) => x[1]);

    pins.push(
      <Polygon
        key={"area"}
        options={{
          strokeColor: "#000",
          strokeWeight: 2,
          strokeOpacity: this.props.mapType === "hybrid" ? 0.8 : 0.2,
          fillOpacity: 0,
        }}
        path={report.area.geometry.coordinates.map(([lat, lng]) => ({
          lat,
          lng,
        }))}
      />
    );

    return pins;
  }

  getArrayBounds(polyArray) {
    const bounds = new google.maps.LatLngBounds();

    for (let polys = 0; polys < polyArray.length; polys++) {
      const coord = polyArray[polys];
      bounds.extend({ lat: coord[0], lng: coord[1] });
    }

    return bounds;
  }

  updateMap() {
    const { area } = this.props;
    if (
      !area.currentReport ||
      this.mapReportId === area.id + area.currentReport.id ||
      !this.mapRef
    ) {
      return;
    }

    this.setState({ currentVehicle: 0 });

    this.mapReportId = area.id + area.currentReport.id;
    this.mapRef.fitBounds(
      this.getArrayBounds(area.currentReport.area.geometry.coordinates),
      { left: 200, top: -100, right: -100, bottom: -100 }
    );

    const vehicles = {};
    area.currentReport.area.travels.forEach((travel) => {
      let vid = travel.vehicle;
      if (!(vid in vehicles)) {
        vehicles[vid] = {
          licensePlate: vid,
          firstSeen: travel.from,
          lastSeen: travel.until,
          speedSamples: travel.type !== "parked" ? [travel.speed] : [],
          durations: {
            parked: travel.type === "parked" ? travel.duration : 0,
            surveilling: travel.type === "surveilling" ? travel.duration : 0,
            speeding: travel.type === "speeding" ? travel.duration : 0,
          },
        };
        return;
      }

      vehicles[vid].durations[travel.type] += travel.duration;
      if (travel.type !== "parked") {
        vehicles[vid].speedSamples.push(travel.speed);
        if (travel.from < vehicles[vid].firstSeen) {
          vehicles[vid].firstSeen = travel.from;
        }
        if (travel.until > vehicles[vid].lastSeen) {
          vehicles[vid].lastSeen = travel.until;
        }
      }
    });
    this.setState({
      vehicles: Object.values(vehicles).map((vehicle) => {
        vehicle.averageSpeed = 0;
        if (vehicle.speedSamples.length) {
          vehicle.averageSpeed =
            vehicle.speedSamples.reduce((count, val) => count + val, 0) /
            vehicle.speedSamples.length;
          delete vehicle.speedSamples;
        }
        return vehicle;
      }),
    });
  }

  getProgressWidth() {
    const { area } = this.props;
    const { totals } = area.currentReport;

    const timePerDay = area.currentReport.area.timePerWeekday[area.currentReport.title.substring(0, 3)]

    const percentage = Math.max(
      3,
      Math.min(100, (totals.surveillanceTime / timePerDay) * 100)
    );

    return Math.round(percentage) + "%";
  }

  getBarHeight(durations, label) {
    const highest = Object.values(durations).reduce(
      (prev, cur) => (cur > prev ? cur : prev),
      0
    );
    if (!highest) {
      return 0;
    }

    return (
      Math.round(
        Math.max(0, Math.min(100, (durations[label] / highest) * 100))
      ) + "%"
    );
  }

  render() {
    const { area, match, mapType } = this.props;

    return (
      <>
        <div className="map-backdrop" key="map">
          <Map
            mapType={mapType}
            onMapMounted={(ref) => {
              this.mapRef = ref;
              this.updateMap();
            }}
          >
            {area.currentReport ? this.renderMarkers(area.currentReport) : null}
          </Map>
        </div>
        {area && area.reportsDaily ? (
          <div className="body-content">
            {match.params.area === area.id &&
            !match.params.id &&
            !area.reportsDailyLoading &&
            area.reportsDaily.length ? (
              <Redirect
                to={`/areas/${area.id}/day/${area.reportsDaily[0].id}`}
              />
            ) : null}
            {area.currentReport ? (
              <a
                target="_blank"
                className="download-pdf"
                href={`https://surveillance.report/reports/${area.id}/day/${area.currentReport.id}.pdf`}
              >
                Download PDF
              </a>
            ) : null}
            <div className="report-left">
              <div className="legend">
                {area.reportsDaily.map((report) => (
                  <NavLink
                    to={`/areas/${area.id}/day/${report.id}`}
                    className="legend-item"
                    key={report.id}
                  >
                    {report.title}
                    <br />
                    <span className="legend-stat">
                      <Icon>timelapse</Icon>
                      {secondsToHumanTime(report.totals.surveillanceTime)}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="legend-stat">
                      <Icon>directions_car</Icon>
                      {report.totals.vehiclesCount}x
                    </span>
                  </NavLink>
                ))}
                {area.reportsDaily.length < 2 ? (
                  <div className="placeholder">
                    Rapporten worden beschikbaar binnen 24 uur nadat het gebied
                    toegevoegd is.
                  </div>
                ) : null}
              </div>
              {area.currentReport ? (
                <div className="detail">
                  <div className="headline">
                    <div className="timestamp-main">
                      {secondsToHumanTime(
                        area.currentReport.totals.surveillanceTime
                      )}
                    </div>
                    <div className="timestamp-label">
                      SURVEILLANCETIJD (UUR)
                    </div>
                    <div className="progress green">
                      {area.currentReport.totals.surveillanceTime ? (
                        <div
                          className="bar orange"
                          style={{ width: this.getProgressWidth() }}
                        />
                      ) : null}
                    </div>
                    <div className="labels">
                      <span className="desc orange">
                        {secondsToHumanTime(
                          area.currentReport.totals.surveillanceTime
                        )}{" "}
                        dit gebied
                      </span>
                      <span className="desc green">
                        {secondsToHumanTime(area.currentReport.area.timePerWeekday[area.currentReport.title.substring(0, 3)])}{" "}
                        afspraak
                      </span>
                    </div>
                  </div>
                  {this.state.vehicles.length ? (
                    <div className="cars">
                      <a
                        className={`cars-button next ${
                          this.state.currentVehicle >=
                          this.state.vehicles.length - 1
                            ? "disabled"
                            : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            currentVehicle: Math.min(
                              this.state.vehicles.length - 1,
                              this.state.currentVehicle + 1
                            ),
                          })
                        }
                      />
                      <a
                        className={`cars-button prev ${
                          this.state.currentVehicle === 0 ? "disabled" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            currentVehicle: Math.max(
                              this.state.currentVehicle - 1,
                              0
                            ),
                          })
                        }
                      />

                      {this.state.vehicles.map((vehicle, id) =>
                        id === this.state.currentVehicle ? (
                          <div key={vehicle.licensePlate} className="car">
                            <img src={Car} className="car-image" />
                            <div className="plate">{vehicle.licensePlate}</div>
                            <div className="stat">
                              {moment(vehicle.firstSeen).format("HH:mm")} -{" "}
                              {moment(vehicle.lastSeen).format("HH:mm")}
                              <span>Tijd in - uit</span>
                            </div>
                            <div className="stat">
                              {Math.round(vehicle.averageSpeed)} km/u
                              <span>Gemiddelde snelheid</span>
                            </div>
                            <div className="graph">
                              <div className="graph-left">
                                <div
                                  className="bar green"
                                  style={{
                                    height: this.getBarHeight(
                                      vehicle.durations,
                                      "surveilling"
                                    ),
                                  }}
                                />
                                <div
                                  className="bar orange"
                                  style={{
                                    height: this.getBarHeight(
                                      vehicle.durations,
                                      "speeding"
                                    ),
                                  }}
                                />
                                <div
                                  className="bar blue"
                                  style={{
                                    height: this.getBarHeight(
                                      vehicle.durations,
                                      "parked"
                                    ),
                                  }}
                                />
                              </div>
                              <div className="graph-legend">
                                <div className="leg green">
                                  <strong>
                                    {secondsToHumanTime(
                                      vehicle.durations.surveilling
                                    )}
                                  </strong>{" "}
                                  - gesurveilleerd
                                </div>
                                <div className="leg orange">
                                  <strong>
                                    {secondsToHumanTime(
                                      vehicle.durations.speeding
                                    )}
                                  </strong>{" "}
                                  - {area.currentReport.sla.maxSpeed}+ km/u
                                </div>
                                <div className="leg blue">
                                  <strong>
                                    {secondsToHumanTime(
                                      vehicle.durations.parked
                                    )}
                                  </strong>{" "}
                                  - geparkeerd
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default connect(
  ({ app }) => ({
    area: app.currentArea,
    areas: app.areas,
    loading: app.loading,
    mapType: app.preferences.mapTypePreference || "roadmap",
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setCurrentArea,
        loadReports,
        loadCurrentReport,
      },
      dispatch
    )
)(DayReport);
