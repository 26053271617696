import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import "../admin.scss";
import UsersOverview from "./admin/UsersOverview";
import AreaEdit from "./admin/AreaEdit";

export default class Admin extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <Switch>
        <Route path="/admin/new" component={AreaEdit} />
        <Route path="/admin/edit/:userId" component={AreaEdit} />
        <Route path="/admin" component={UsersOverview} />
      </Switch>
    );
  }
}
