import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";

import appReducer from "./reducers/app";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();
export const store = createStore(
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
  }),
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
);
