import React from "react";
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";

import mapStyle from "./mapStyle.js";
import "./style.scss";

class Map extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <GoogleMap
        options={{
          styles: mapStyle,
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeId: this.props.mapType || "roadmap",
        }}
        ref={this.props.onMapMounted}
        defaultZoom={15}
        defaultCenter={{ lat: 50.8542971, lng: 5.7289186 }}
      >
        {this.props.children}
      </GoogleMap>
    );
  }
}

export default compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDjJFDRDQp2KABLkqjgnPEWw73j8iTCL30&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(Map);
