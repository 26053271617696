import { setReportingPreference } from "../../services/API";

export const setPreference = (reportingPreference) => async (dispatch) => {
  try {
    await setReportingPreference(reportingPreference);
    dispatch({
      type: "SET_PREFERENCE",
      data: {
        reportingPreference,
      },
    });
  } catch (_) {}
};
