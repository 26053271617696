const initialState = {
  loading: true,
  sla: {
    maxStopTime: 99,
    maxSpeed: 99,
  },
  areas: [],
  currentReport: null,
  currentReportLoading: false,
  reportsMonthly: null,
  reportsMonthlyLoading: false,
  currentArea: null,
  id: null,
  name: null,
  email: null,
  preferences: {
    mapTypePreference: "roadmap",
    reportingPreference: "none",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.data };
    case "SET_PREFERENCE":
      return {
        ...state,
        preferences: { ...state.preferences, ...action.data },
      };
    case "SET_DEFAULT_AREA":
      if (state.currentArea) {
        return state;
      }
      return { ...state, currentArea: action.defaultArea };
    case "SET_AREA_DATA":
      if (!state.currentArea || state.currentArea.id !== action.areaId) {
        return state;
      }
      return {
        ...state,
        currentArea: { ...state.currentArea, ...action.data },
      };
    case "SET_CURRENT_AREA":
      if (state.currentArea && state.currentArea.id === action.currentArea.id) {
        return state; // don't update if equal
      }
      return { ...state, currentArea: action.currentArea };
    default:
      return state;
  }
};
