import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

export default class Dropdown extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
  };

  static activeInstance;

  state = {
    active: false,
    className: "",
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }

    if (this.contentNode.contains(e.target)) {
      return setTimeout(() => this.hide(), 200);
    }

    if (this.state.active) {
      this.hide();
    }
  }

  show() {
    if (Dropdown.activeInstance) {
      Dropdown.activeInstance.hide();
    }

    this.setState({ active: true });
    Dropdown.activeInstance = this;
  }

  hide() {
    this.setState({ active: false });
    Dropdown.activeInstance = null;
  }

  toggle() {
    return !this.state.active ? this.show() : this.hide();
  }

  render() {
    return (
      <div
        className={`dropdown${this.state.active ? " active" : ""} ${
          this.props.className
        }`}
      >
        <a
          ref={(node) => {
            this.node = node;
          }}
          onClick={() => this.toggle()}
          className="dropdown-label"
        >
          {this.props.title}
          <span className="dropdown-caret">
            <svg width="5px" height="4px" viewBox="0 0 5 4">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon fill="#FFFFFF" points="0 0.5 5 0.5 2.5 3.5" />
              </g>
            </svg>
          </span>
        </a>
        <div
          className="dropdown-content"
          ref={(node) => {
            this.contentNode = node;
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
