import { setMapTypePreference } from "../../services/API";

export const setMapType = (mapTypePreference) => async (dispatch) => {
  try {
    await setMapTypePreference(mapTypePreference);
    dispatch({
      type: "SET_PREFERENCE",
      data: {
        mapTypePreference,
      },
    });
  } catch (_) {}
};
