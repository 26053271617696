import React from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";

import Icon from "../../components/Icon";
import { getUsers, deleteCustomer } from "../../services/API";

const UsersOverview = () => {
  const { data, mutate } = useSWR(" ", getUsers);

  const remove = async (orgId) => {
    if (
      !confirm(
        "Weet je zeker dat je deze klant en alle gerelateerde data wil verwijderen? Dit kan niet ongedaan gemaakt worden."
      )
    ) {
      return;
    }

    await deleteCustomer(orgId);

    return mutate();
  };

  const loginAs = (orgId) => {
    if (
      !confirm(
        "Hiermee log je in als deze gebruiker."
      )
    ) {
      return;
    }

    localStorage["surveillance.impersonate"] = orgId;
    location.href = "/";
  };

  if (!data) {
    return null;
  }

  return (
    <div className="admin-content">
      <div className="admin-nav">
        <div />
        <Link to="/admin/new" className="button">
          Nieuwe klant
        </Link>
      </div>
      <div className="admin-inner">
        <table>
          <thead>
            <tr>
              <th>Klant</th>
              <th>Gebieden</th>
              <th align="right" style={{ textAlign: "right" }}>
                Acties
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((user) => (
              <tr key={user.org}>
                <td>
                  <Link className="title" to={`/admin/edit/${user.org}`}>
                    {user.org}
                  </Link>
                </td>
                <td>{user.areas.length} gebieden</td>
                <td align="right" style={{ textAlign: "right" }}>
                  <a onClick={() => loginAs(user.org)}>
                    <Icon>visibility</Icon>
                  </a>
                  <Link to={`/admin/edit/${user.org}`}>
                    <Icon>edit</Icon>
                  </Link>
                  <a onClick={() => remove(user.org)}>
                    <Icon>delete</Icon>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersOverview;
