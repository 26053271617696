import { getMonthReports } from "../../services/API";

export const loadMonthReports = () => async (dispatch) => {
  dispatch({
    type: "SET_DATA",
    data: {
      reportsMonthlyLoading: true,
    },
  });

  try {
    let data = await getMonthReports();
    dispatch({
      type: "SET_DATA",
      data: {
        reportsMonthly: data,
        reportsMonthlyLoading: false,
      },
    });
  } catch (e) {
    dispatch({
      type: "SET_DATA",
      data: {
        reportsMonthlyLoading: false,
      },
    });
  }
};
