import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Icon from "../Icon";
import Dropdown from "../Dropdown";
import { setPreference } from "../../store/actions/setPreference";
import { setMapType } from "../../store/actions/setMapType";

class Navigation extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const { name, admin, areas, currentArea, setPreference, setMapType, location } =
      this.props;
    const reportingPreference =
      (this.props.preferences && this.props.preferences.reportingPreference) ||
      "daily";
    const mapStylePreference =
      (this.props.preferences && this.props.preferences.mapTypePreference) ||
      "roadmap";
    const impersonate = localStorage["surveillance.impersonate"];
    const dayMode = location.pathname.startsWith("/areas");

    return (
      <>
        {impersonate ? (
          <div className="impersonate-bar">
            <div>
              <strong>Impersonating</strong>:{" "}
              {impersonate}
            </div>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => {
                localStorage.removeItem("surveillance.impersonate");
                window.location.href = "/admin";
              }}
            >
              Stop Impersonating
            </button>
          </div>
        ) : null}
        <nav>
          <div>
            {currentArea ? (
              <>
                <NavLink to={`/areas/${currentArea.id}/day`}>
                  <Icon>event</Icon>
                  Dag
                </NavLink>
                <NavLink to={`/month`}>
                  <Icon>event_note</Icon>
                  Maand
                </NavLink>
              </>
            ) : null}
            {admin && !impersonate ? (
              <NavLink to={`/admin`}>
                <Icon>build</Icon>
                Administratie
              </NavLink>
            ) : null}
          </div>
          <div>
            {dayMode && (
              <Dropdown title={currentArea ? currentArea.title : "Kies gebied"}>
                <ul>
                  {areas.map((area) => (
                    <li key={area.id}>
                      <NavLink to={`/areas/${area.id}/day`}>{area.title}</NavLink>
                    </li>
                  ))}
                </ul>
              </Dropdown>
            )}

            {!impersonate && (
              <Dropdown className="dropdown-account" title={name || "Gebruiker"}>
                <strong>Rapport emails</strong>
                <ul className="checkboxes">
                  <li className={reportingPreference === "daily" ? "active" : ""}>
                    <a onClick={() => setPreference("daily")}>Dagelijks</a>
                  </li>
                  <li
                    className={reportingPreference === "monthly" ? "active" : ""}
                  >
                    <a onClick={() => setPreference("monthly")}>Maandelijks</a>
                  </li>
                  <li className={reportingPreference === "none" ? "active" : ""}>
                    <a onClick={() => setPreference("none")}>Geen</a>
                  </li>
                </ul>
                <strong style={{ marginTop: 10 }}>Type kaart</strong>
                <ul className="checkboxes">
                  <li
                    className={mapStylePreference === "roadmap" ? "active" : ""}
                  >
                    <a onClick={() => setMapType("roadmap")}>Normaal</a>
                  </li>
                  <li
                    className={mapStylePreference === "terrain" ? "active" : ""}
                  >
                    <a onClick={() => setMapType("terrain")}>Terrein</a>
                  </li>
                  <li className={mapStylePreference === "hybrid" ? "active" : ""}>
                    <a onClick={() => setMapType("hybrid")}>Satelliet</a>
                  </li>
                </ul>
                <ul className="down">
                  <li>
                    <a
                      onClick={async() => {
                        await Auth.signOut();
                        window.location.reload();
                      }}
                    >
                      Uitloggen
                    </a>
                  </li>
                </ul>
              </Dropdown>
            )}
          </div>
        </nav>
      </>
    );
  }
}

export default connect(
  ({ app }) => ({
    name: app.name,
    admin: app.admin,
    areas: app.areas,
    currentArea: app.currentArea,
    preferences: app.preferences
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setPreference,
        setMapType
      },
      dispatch
    )
)(withRouter(Navigation));
