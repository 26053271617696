import { getUser } from "../../services/API";
import { loadAreas } from "./loadAreas";

export const loadData = () => async (dispatch) => {
  try {
    let data = await getUser();
    dispatch({
      type: "SET_DATA",
      data: {
        ...data,
      },
    });
    dispatch(loadAreas());
  } catch (e) {
    dispatch({
      type: "SET_DATA",
      data: {
        loading: false,
        account: null,
      },
    });
  }
};
