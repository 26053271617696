import React from "react";
import PropTypes from "prop-types";

import Logo from "../../assets/logo.svg";

import "./style.scss";

export default class MobileCompat extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className="mobile-compat-overlay">
        <img src={Logo} />
        Sorry, Surveillance Reports is momenteel alleen te gebruiken op
        computers en tablets. Zoek een groter scherm bij jou in de buurt om te
        beginnen!
      </div>
    );
  }
}
