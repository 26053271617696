import axios from "axios";
import { Auth } from "aws-amplify";

const getClient = () => {
  const client = axios.create({
    baseURL: "/api/",
  });

  client.interceptors.request.use(async (config) => {
    const session = await Auth.currentSession();
    const token = session && session.getIdToken().jwtToken;
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    return config;
  });

  return client;
};

export let client = getClient();

export const getAreas = async () => {
  const impersonate = localStorage["surveillance.impersonate"];
  if (impersonate) {
    return (await client.get(`orgs/${impersonate}/areas`)).data;
  }
  return (await client.get(`areas`)).data;
};

export const setReportingPreference = async (reportingPreference) => {
  return await client.post(`preferences`, {
    reportingPreference,
  });
};

export const setMapTypePreference = async (mapType) => {
  return await client.post(`preferences`, {
    mapType,
  });
};

export const getUser = async () => {
  return (await client.get(`me`)).data;
};

export const getReports = async (areaId, type) => {
  return (
    await client.get(
      `areas/${areaId}/history/${type === "daily" ? "day" : type}`
    )
  ).data;
};

export const getMonthReports = async () => {
  const impersonate = localStorage["surveillance.impersonate"];
  if (impersonate) {
    return (await client.get(`orgs/${impersonate}/history/month`)).data;
  }
  return (await client.get(`org/history/month`)).data;
};

export const getSingleReport = async (areaId, type = null, id = null) => {
  const baseUrl = `https://surveillance.report`;
  return (
    await client.get(
      !type
        ? `${baseUrl}${areaId}`
        : `${baseUrl}/reports/${areaId}/day/${id}.json`
    )
  ).data;
};

export const getUsers = async () => {
  const areas = (await client.get(`orgs/__all__/areas`)).data;
  return Object.values(
    areas.reduce((acc, area) => {
      if (!(area.org in acc)) {
        acc[area.org] = {
          org: area.org,
          areas: [area],
        };
      } else {
        acc[area.org].areas.push(area);
      }
      return acc;
    }, {})
  );
};

export const getOrgUsers = async (orgId) => {
  return (await client.get(`orgs/${orgId}/users`)).data;
};

export const getUserAreas = async (userId) => {
  return (await client.get(`orgs/${userId}/areas`)).data;
};

export const deleteCustomer = async (orgId) => {
  const areas = (await client.get(`orgs/${orgId}/areas`)).data;
  for (const area of areas) {
    if (orgId && area.org && area.org === orgId) {
      await client.delete(`orgs/${orgId}/areas/${area.id}`);
    }
  }
  const users = (await client.get(`orgs/${orgId}/users`)).data;
  for (const user of users) {
    await client.delete(`users/${user.id}`);
  }
};

export const updateUser = async (userId, values) => {
  await client.post(`users/${userId}`, values);
}

export const deleteUser = async (userId) => {
  await client.delete(`users/${userId}`);
};

export const deleteArea = async (areaId) => {
  return await client.delete(`areas/${areaId}`);
};

export const createUser = async (name, email, orgId) => {
  return (
    await client.post(`users`, {
      org: orgId,
      name,
      email,
    })
  ).data;
};

export const createArea = async (
  orgId,
  title,
  timePerDay,
  fromTime,
  timePerWeekday,
  untilTime,
  coordinates,
  maxSpeed,
  maxStopTime
) => {
  return (
    await client.post(`orgs/${orgId}/areas`, {
      title,
      fromTime,
      untilTime,
      coordinates,
      timePerWeekday: {
        Mon: timePerWeekday.Mon || 0,
        Tue: timePerWeekday.Tue || 0,
        Wed: timePerWeekday.Wed || 0,
        Thu: timePerWeekday.Thu || 0,
        Fri: timePerWeekday.Fri || 0,
        Sat: timePerWeekday.Sat || 0,
        Sun: timePerWeekday.Sun || 0,
      },
      maxSpeed,
      maxStopTime,
    })
  ).data.data;
};

export const updateArea = async (
  orgId,
  areaId,
  title,
  timePerDay,
  fromTime,
  timePerWeekday,
  untilTime,
  coordinates,
  maxSpeed,
  maxStopTime
) => {
  return (
    await client.post(`orgs/${orgId}/areas/${areaId}`, {
      title,
      fromTime,
      untilTime,
      coordinates,
      timePerWeekday: {
        Mon: timePerWeekday.Mon || 0,
        Tue: timePerWeekday.Tue || 0,
        Wed: timePerWeekday.Wed || 0,
        Thu: timePerWeekday.Thu || 0,
        Fri: timePerWeekday.Fri || 0,
        Sat: timePerWeekday.Sat || 0,
        Sun: timePerWeekday.Sun || 0,
      },
      maxSpeed,
      maxStopTime,
    })
  ).data.data;
};
