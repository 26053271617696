import { getAreas } from "../../services/API";

export const loadAreas = () => async (dispatch) => {
  try {
    let data = await getAreas();
    dispatch({
      type: "SET_DATA",
      data: {
        loading: false,
        areas: data,
      },
    });
    if (data.length) {
      dispatch({
        type: "SET_DEFAULT_AREA",
        defaultArea: data[0],
      });
    }
  } catch (e) {
    dispatch({
      type: "SET_DATA",
      data: {
        loading: false,
      },
    });
  }
};
