import { getSingleReport } from "../../services/API";

export const loadCurrentMonthReport = (report) => async (dispatch) => {
  if (!report?.id) {
    return;
  }

  dispatch({
    type: "SET_DATA",
    data: {
      currentReportLoading: report.id,
    },
  });

  try {
    let data = await getSingleReport(report.jsonUrl);
    dispatch({
      type: "SET_DATA",
      data: {
        currentReport: {...report, ...data},
        currentReportLoading: false,
      },
    });
  } catch (e) {
    dispatch({
      type: "SET_DATA",
      data: {
        currentReportLoading: false,
      },
    });
  }
};
