export const secondsToHumanTime = (ts) => {
  const hr = Math.floor(ts / 3600);
  let mn = Math.ceil((ts - hr * 3600) / 60);

  if (mn < 10) {
    mn = "0" + mn;
  }

  return hr + ":" + mn;
};
