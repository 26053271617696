import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Authenticator } from "@aws-amplify/ui-react";

import Main from "./Main";

import { store, history } from "../store";

const RootComponent = () => {
  useEffect(() => {
    if (
      window.location.href.includes("beta.surveillance.report")
    ) {
      window.location.href = "https://surveillance.report/?utm_source=beta";
    }
    if (
      window.location.href.includes("www.surveillance.report")
    ) {
      window.location.href = "https://surveillance.report/?utm_source=www";
    }
    if (
      window.location.href.includes("localhost") &&
      document.getElementById("loader")
    ) {
      document.body.removeChild(document.getElementById("loader"));
      return;
    }
    setTimeout(() => {
      document.getElementById("loader").className = "hidden";
    }, 1000);
    setTimeout(() => {
      document.body.removeChild(document.getElementById("loader"));
    }, 1500);
  }, []);

  return (
    <Provider store={store}>
      <Authenticator loginMechanisms={["email"]} signUpAttributes={[]} variation="modal">
        {() => (
          <ConnectedRouter history={history}>
            <Main />
          </ConnectedRouter>
        )}
      </Authenticator>
    </Provider>
  );
};

export default RootComponent;
