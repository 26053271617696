import React from "react";
import PropTypes from "prop-types";
import Map from "../components/Map";
import Icon from "../components/Icon";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Polygon } from "react-google-maps";
import { NavLink, Redirect } from "react-router-dom";

import { secondsToHumanTime } from "../services/timeFormatter";

import { loadMonthReports } from "../store/actions/loadMonthReports";
import { loadCurrentMonthReport } from "../store/actions/loadCurrentMonthReport";
import Tooltip from "../components/Tooltip";

class MonthReport extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  state = {
    areas: [],
  };

  constructor(props) {
    super(props);

    this.mapReportId = null;
    this.mapRef = {};

    this.loadData(props);
  }

  componentDidUpdate() {
    this.loadData(this.props);
  }

  async loadData() {
    let {
      match,
      currentReport,
      currentReportLoading,
      reportsMonthly,
      reportsMonthlyLoading,
      loadMonthReports,
      loadCurrentMonthReport,
    } = this.props;

    if (!reportsMonthly && !reportsMonthlyLoading) {
      await loadMonthReports();
    }

    if (
      reportsMonthly &&
      (!currentReport || currentReport.id !== match.params.id) &&
      currentReportLoading !== match.params.id
    ) {
      const report = reportsMonthly.find((report) => report.id === match.params.id);
      if(report) {
        await loadCurrentMonthReport(report);
      }
    }

    this.updateMapValues();
  }

  renderMarkers(area) {
    return (
      <Polygon
        options={{
          strokeColor: "#2d89ea",
          strokeWeight: 3,
          fillColor: "#2d89ea",
          fillOpacity: 0.4,
        }}
        path={area.geometry.coordinates.map(([lat, lng]) => ({
          lat,
          lng: lng + 0.0000001,
        }))}
      />
    );
  }

  getArrayBounds(polyArray) {
    const bounds = new google.maps.LatLngBounds();

    for (let polys = 0; polys < polyArray.length; polys++) {
      const coord = polyArray[polys];
      bounds.extend({ lat: coord[0], lng: coord[1] });
    }

    return bounds;
  }

  updateMap(areaId) {
    const { currentReport } = this.props;
    if (!currentReport) {
      return;
    }

    currentReport.areas.forEach((area) => {
      if (areaId === area.id && this.mapRef[area.id]) {
        this.mapRef[area.id].fitBounds(
          this.getArrayBounds(area.geometry.coordinates),
          { left: 20, top: 20, right: 20, bottom: 50 }
        );
        setTimeout(
          () => google.maps.event.trigger(this.mapRef[area.id], "resize"),
          100
        );
      }
    });
  }

  updateMapValues() {
    const { currentReport } = this.props;
    if (!currentReport || this.mapReportId === currentReport.id) {
      return;
    }

    this.mapReportId = currentReport.id;

    const areas = [];
    currentReport.areas.forEach((area) => {
      const compiledArea = {
        id: area.id,
        geometry: area.geometry,
        title: area.title,
        vehicles: {},
        speedSamples: [],
        durations: {
          parked: 0,
          surveilling: 0,
          speeding: 0,
        },
      };

      area.travels.forEach((travel) => {
        let vid = travel.vehicle;
        if (!(vid in compiledArea.vehicles)) {
          compiledArea.vehicles[vid] = {
            licensePlate: vid,
          };
        }

        compiledArea.durations[travel.type] += travel.duration;
        if (travel.type !== "parked") {
          compiledArea.speedSamples.push(travel.speed);
        }
      });

      compiledArea.vehicles = Object.values(compiledArea.vehicles);

      areas.push(compiledArea);
    });
    this.setState({
      areas: areas.map((area) => {
        area.averageSpeed = 0;
        if (area.speedSamples.length) {
          area.averageSpeed =
            area.speedSamples.reduce((count, val) => count + val, 0) /
            area.speedSamples.length;
          delete area.speedSamples;
        }
        return area;
      }),
    });
  }

  getProgressWidth() {
    const { currentReport } = this.props;
    const { totals, totalGoalTime } = currentReport;

    const percentage = Math.max(
      3,
      Math.min(100, (totals.surveillanceTime / totalGoalTime) * 100)
    );

    return Math.round(percentage) + "%";
  }

  getBarHeight(durations, label) {
    const highest = Object.values(durations).reduce(
      (prev, cur) => (cur > prev ? cur : prev),
      0
    );
    if (!highest) {
      return 0;
    }

    return (
      Math.round(
        Math.max(0, Math.min(100, (durations[label] / highest) * 100))
      ) + "%"
    );
  }

  render() {
    const { reportsMonthlyLoading, currentReport, reportsMonthly, match } =
      this.props;

    if (reportsMonthlyLoading || !reportsMonthly) {
      return null;
    }

    if (!match.params.id && !reportsMonthlyLoading && reportsMonthly.length) {
      return <Redirect to={`/month/${reportsMonthly[0].id}`} />;
    }

    return (
      <>
        <div className="body-content monthly">
          {currentReport ? (
            <a
              target="_blank"
              className="download-pdf"
              href={currentReport.pdfUrl}
            >
              Download PDF
            </a>
          ) : null}
          <div className="report-left">
            <div className="legend">
              {reportsMonthly.map((report) => (
                <NavLink
                  to={`/month/${report.id}`}
                  className="legend-item"
                  key={report.id}
                >
                  {report.title}
                  <br />
                  <span className="legend-stat">
                    <Icon>timelapse</Icon>
                    {secondsToHumanTime(report.totals.surveillanceTime)}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="legend-stat">
                    <Icon>directions_car</Icon>
                    {report.totals.vehiclesCount}x
                  </span>
                </NavLink>
              ))}
              {!reportsMonthlyLoading && !reportsMonthly.length ? (
                <div className="placeholder">
                  Maandrapporten worden beschikbaar zodra de eerste maand
                  afgelopen is.
                </div>
              ) : null}
            </div>
            {currentReport ? (
              <div className="detail">
                <div className="headline">
                  <div className="timestamp-main">
                    {secondsToHumanTime(currentReport.totals.surveillanceTime)}
                  </div>
                  <div className="timestamp-label">
                    TOTALE SURVEILLANCETIJD (UUR)
                  </div>
                  <div className="progress green">
                    {currentReport.totals.surveillanceTime ? (
                      <div
                        className="bar blue"
                        style={{ width: this.getProgressWidth() }}
                      />
                    ) : null}
                  </div>
                  <div className="labels">
                    <span className="desc blue">
                      {secondsToHumanTime(
                        currentReport.totals.surveillanceTime
                      )}{" "}
                      surveillance
                    </span>
                    <span className="desc green">
                      {secondsToHumanTime(currentReport.totalGoalTime)} afspraak
                    </span>
                  </div>
                </div>
                <div className="report-cards">
                  {this.state.areas.length
                    ? this.state.areas.map((area) => (
                        <div key={area.id} className="cars">
                          <div className="car">
                            <div className="car-map">
                              <Map
                                mapType="hybrid"
                                onMapMounted={(ref) => {
                                  this.mapRef[area.id] = ref;
                                  this.updateMap(area.id);
                                }}
                              >
                                {this.renderMarkers(area)}
                              </Map>
                            </div>
                            <h3>{area.title}</h3>
                            <div className="stat">
                              <div
                                style={{ minHeight: 36, whiteSpace: "nowrap" }}
                              >
                                {(area.vehicles.length > 5
                                  ? area.vehicles.slice(0, 4)
                                  : area.vehicles
                                ).map((vehicle, index) => (
                                  <Tooltip
                                    key={vehicle.licensePlate}
                                    content={vehicle.licensePlate}
                                  >
                                    <img
                                      key={index}
                                      title={vehicle.licensePlate}
                                      src={require("../assets/car.png")}
                                    />
                                  </Tooltip>
                                ))}
                                {area.vehicles.length > 5 ? (
                                  <Tooltip
                                    content={area.vehicles
                                      .slice(4)
                                      .map((ve) => ve.licensePlate)
                                      .join("\n")}
                                  >
                                    <span className="plus-more">
                                      +{area.vehicles.length - 4}
                                    </span>
                                  </Tooltip>
                                ) : null}
                              </div>
                              <span>
                                Auto's in het gebied ({area.vehicles.length})
                              </span>
                            </div>
                            <div className="stat">
                              {Math.round(area.averageSpeed)} km/u
                              <span>Gemiddelde snelheid</span>
                            </div>
                            <div className="graph">
                              <div className="graph-left">
                                <div
                                  className="bar green"
                                  style={{
                                    height: this.getBarHeight(
                                      area.durations,
                                      "surveilling"
                                    ),
                                  }}
                                />
                                <div
                                  className="bar orange"
                                  style={{
                                    height: this.getBarHeight(
                                      area.durations,
                                      "speeding"
                                    ),
                                  }}
                                />
                                <div
                                  className="bar blue"
                                  style={{
                                    height: this.getBarHeight(
                                      area.durations,
                                      "parked"
                                    ),
                                  }}
                                />
                              </div>
                              <div className="graph-legend">
                                <div className="leg green">
                                  <strong>
                                    {secondsToHumanTime(
                                      area.durations.surveilling
                                    )}
                                  </strong>{" "}
                                  - gesurveilleerd
                                </div>
                                <div className="leg orange">
                                  <strong>
                                    {secondsToHumanTime(
                                      area.durations.speeding
                                    )}
                                  </strong>{" "}
                                  - {area?.sla?.maxSpeed ? `${area?.sla?.maxSpeed}+ km/u` : 'te snel'}
                                </div>
                                <div className="leg blue">
                                  <strong>
                                    {secondsToHumanTime(area.durations.parked)}
                                  </strong>{" "}
                                  - geparkeerd
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  ({ app }) => ({
    currentReport: app.currentReport,
    currentReportLoading: app.currentReportLoading,
    reportsMonthly: app.reportsMonthly,
    reportsMonthlyLoading: app.reportsMonthlyLoading,
    mapType: app.preferences.mapTypePreference || "roadmap",
  }),
  (dispatch) =>
    bindActionCreators(
      {
        loadMonthReports,
        loadCurrentMonthReport,
      },
      dispatch
    )
)(MonthReport);
