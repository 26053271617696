import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { Amplify, I18n, Auth } from "aws-amplify";
import { AmplifyProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import RootComponent from "./components/RootComponent";

import "./app.scss";

Sentry.init({
  dsn: "https://12becf1322a2419db7e0f5db117d8b22@sentry.io/1536334",
});


const authConfig = {
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_kiHyngGQe',
  userPoolWebClientId: '2rld7mi993l6nmh8mtj9rphfk3'
}

I18n.setLanguage('nl');
I18n.putVocabulariesForLanguage('nl', {
  'Username': 'Email address',
  'Password': 'Password'
});

Amplify.configure({
  Auth: authConfig
});
Auth.configure(authConfig);

ReactDOM.render(<AmplifyProvider><RootComponent /></AmplifyProvider>, document.getElementById("app"));
